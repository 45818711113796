<template>
  <div class="wrapper wrapper--default p2 m1">
    <Loading v-if="loading" />
    <div v-if="!loading" class="flexbox spaceevenly w100 p2">
      <table :class="`table__border--${bu}`">
        <thead :class="`table__head--${bu}`">
          <th>In behandeling</th>
          <th>Totaal</th>
          <th>&lt;14 dagen</th>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td class="table__data--right">{{ total_processing }}</td>
            <td class="table__data--right">
              {{ total_processing_two_weeks }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Tabs v-if="!loading && bu == 210">
      <Tab title="Nog aan te vragen">
        <div class="flexbox spaceevenly">
          <div class="p2 overflow">
            <div class="_title _text-center">Voorraad</div>
            <RdwTable :table_data="still_to_request_S" :table_style="bu" />
          </div>
          <div class="p2 overflow">
            <div class="_title _text-center">Verkocht</div>
            <RdwTable :table_data="still_to_request_V" :table_style="bu" />
          </div>
        </div>
      </Tab>
      <Tab title="Niet Compleet" :onOpen="loadNotCompleted">
        <Loading v-if="loading_not_completed" />
        <div v-else class="flexbox spaceevenly">
          <div class="p2 overflow">
            <div class="_title _text-center">Voorraad</div>
            <RdwTable :table_data="not_complete_S" :table_style="bu" />
          </div>
          <div class="p2 overflow">
            <div class="_title _text-center">Verkocht</div>
            <RdwTable :table_data="not_complete_V" :table_style="bu" />
          </div>
        </div>
      </Tab>
    </Tabs>
    <div v-if="!loading && bu == 200" class="flexbox spaceevenly">
      <div class="p2 flex _column overflow mw50">
        <div class="_title _text-center">Voorraad</div>
        <RdwTable :table_data="rdw_trucks_S" :table_style="bu" />
      </div>
      <div class="p2 flex _column overflow mw50">
        <div class="_title _text-center">Verkocht</div>
        <RdwTable :table_data="rdw_trucks_V" :table_style="bu" />
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import Loading from "@/components/Loading.vue";
import RdwTable from "@/components/RdwTable.vue";
import request from "@/functions/request.js";

export default {
  props: ["bu"],
  components: { Tab, Tabs, Loading, RdwTable },
  data: () => ({
    loading: true,
    loading_not_completed: true,
    still_to_request_V: null,
    still_to_request_S: null,
    not_complete_V: null,
    not_complete_S: null,
    rdw_trucks_S: null,
    rdw_trucks_V: null,
    total_processing: null,
    total_processing_two_weeks: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.loading_not_completed = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      if (bu == 210) {
        request(`rdw/still-to-request/${bu}`, "GET").then(
          ({ still_to_request_V, still_to_request_S }) => {
            this.still_to_request_V = still_to_request_V;
            this.still_to_request_S = still_to_request_S;
            this.loading = false;
          }
        );
      } else {
        request(`rdw/still-to-request/${bu}`, "GET").then(
          ({ rdw_trucks_S, rdw_trucks_V }) => {
            this.rdw_trucks_S = rdw_trucks_S;
            this.rdw_trucks_V = rdw_trucks_V;
            this.loading = false;
          }
        );
      }
      request(`rdw/processing/${bu}`, "GET").then(
        ({ total_processing, total_processing_two_weeks }) => {
          this.total_processing = total_processing;
          this.total_processing_two_weeks = total_processing_two_weeks;
        }
      );
    },

    loadNotCompleted() {
      if (this.loading_not_completed && !this.not_complete_S) {
        request(`rdw/not-complete/${this.bu}`, "GET").then(
          ({ not_complete_V, not_complete_S }) => {
            this.not_complete_S = not_complete_S;
            this.not_complete_V = not_complete_V;
            this.loading_not_completed = false;
          }
        );
      }
    },
  },
};
</script>
