<template>
  <table :class="`table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
              <th>Itemnummer</th>
              <th>Status</th>
              <th>Verkoper</th>
              <th>Opmerking</th>
      </thead>
      <tbody>
          <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
              <td><ImageHover :itemnummer="item.Itemnummer" :bu="table_style" /></td>
              <td>{{ item.RdwStatus }}</td>
              <td>{{ item.Verkoper }}</td>
              <td>{{ item.RdwOpmerking }}</td>
          </tr>
      </tbody>
  </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
    props: {
        table_data: [Object, Array],
        table_style: [Number, String]
    },
    components: { ImageHover },
}
</script>